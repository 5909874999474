@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}


@media screen and (max-width:950px) {
	.card-responsive{
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.single-card{
		margin-left: 0px !important;
		margin-top: 10px !important;
	}
	.scoredResult{
		flex-direction: column !important;
	}
	.eachBadge{
		width: 100% !important;
	}
}

body.dark .amplify-input {
    border-width: 2px !important;
    border-color: white !important;
}

body.dark .amplify-input::placeholder,
body.dark .amplify-input[value],
body.dark .amplify-label {
    color: white !important;
}

body.dark .amplify-button {
    color: white !important;
    border-color: white !important;
}

body.dark .amplify-button:hover {
    color: black !important;
    border-color: black !important;
}




/* CSS for the dropdown */
.dropdown {
    position: relative;
    /* display: inline-block; */
	display: flex;
    flex-direction: row;
    justify-content: center;
}

.dropdown-content {
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-item {
    display: block;
    text-decoration: none;
}